<template>
  <div>
    <div
      :style="sectorCSS + `background-color: ${sectorColor}; border-color: ${sectorColor}`"
      class="rounded-full"
      :class="' ' + classes"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Sector",
  props: {
    ratio: Number,
    classes: {
      type: String,
      default: "w-40 h-40 border-2",
    },
    sectorColor: {
      type: String,
      default: "rgb(5, 150, 105)",
    },
    bgColor: {
      type: String,
      default: "white",
    },
  },
  computed: {
    sectorCSS: function () {
      if (this.ratio <= 0.5) {
        return `background-image: linear-gradient(${90 + this.ratio * 360}deg, transparent 50%, ${
          this.bgColor
        } 50%), linear-gradient(90deg, ${this.bgColor} 50%, transparent 50%);`;
      } else {
        return `background-image: linear-gradient(${270 + this.ratio * 360}deg, transparent 50%, ${
          this.sectorColor
        } 50%), linear-gradient(90deg, ${this.bgColor} 50%, transparent 50%);`;
      }
    },
  },
};
</script>