import { TDialog } from "vue-tailwind/dist/components";

export default {
  "t-dialog": {
    component: TDialog,
    props: {
      fixedClasses: {
        overlay: "overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed",
        wrapper: "relative mx-auto",
        modal: "overflow-visible relative ",
        close: "flex items-center justify-center",
        dialog: "overflow-visible relative"
      },
      classes: {
        close:
          "bg-gray-100 text-gray-600 rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out hover:bg-gray-200 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
        closeIcon: "fill-current h-4 w-4",

        overlay: "z-40 bg-black bg-opacity-50",
        wrapper: "z-50 max-w-lg px-3 py-12 h-screen flex justify-center items-center",
        dialog: "bg-blue-600 text-gray-100 shadow rounded-xl text-left",

        body: "p-4 pb-1 space-y-3 flex flex-col items-center",
        buttons: "p-4 pt-2 flex space-x-4 justify-center rounded-b",

        iconWrapper: "bg-gray-200 flex flex-shrink-0 h-16 w-16 items-center justify-center rounded-full m-4",
        icon: "w-8 h-8 text-gray-500",
        content: "w-full flex justify-center flex-col",

        titleWrapper: "text-center w-full px-4",
        title: "text-lg font-semibold",

        textWrapper: "text-center w-full p-4 pb-2",
        text: "",

        cancelButton:
          "block p-3 text-gray-100 bg-blue-500 rounded-xl transition hover:opacity-80 active:opacity-60 disabled:cursor-not-allowed w-full max-w-xs",
        okButton:
          "block p-3 text-gray-100 bg-green-500 rounded-xl transition hover:opacity-80 active:opacity-60 disabled:cursor-not-allowed w-full max-w-xs",

        inputWrapper: "mt-3 flex items-center space-x-3",

        input:
          "block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full",
        select:
          "block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed w-full",

        radioWrapper: "flex items-center space-x-2",
        radio:
          "text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed",
        radioText: "",

        checkboxWrapper: "flex items-center space-x-2",
        checkbox:
          "text-blue-500 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed",
        checkboxText: "",

        errorMessage: "text-red-500 block text-sm",

        busyWrapper: "absolute bg-opacity-50 bg-white flex h-full items-center justify-center left-0 top-0 w-full",
        busyIcon: "animate-spin h-6 w-6 fill-current text-gray-500",

        overlayEnterClass: "opacity-0",
        overlayEnterActiveClass: "transition ease-out duration-100",
        overlayEnterToClass: "opacity-100",
        overlayLeaveClass: "opacity-100",
        overlayLeaveActiveClass: "transition ease-in duration-75",
        overlayLeaveToClass: "opacity-0",

        enterClass: "",
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: ""
      }
    }
  }
};
