<template>
  <div>
    <header class="max-w-screen-sm text-gray-100 py-6 px-4 text-center flex flex-col justify-center items-center">
      <router-link to="/"><Logo /></router-link>
    </header>
    <section class="text-gray-100 px-4">
      <h1 class="text-center text-3xl font-bold">Feedback</h1>
      <ul class="text-center py-6">
        <li>What do you think of the game?</li>
        <li>What should be improved?</li>
        <li>What categories, topics and keywords should be added?</li>
      </ul>
      <label for="feedback" class="sr-only">Feedback</label>
      <div class="relative mb-3">
        <div class="absolute text-gray-400 text-sm pointer-events-none right-2 top-1">
          {{ feedbackText.length }}/320
        </div>
        <textarea
          v-model="feedbackText"
          name="feedback"
          id="feedback"
          placeholder="Feedback"
          minlength="1"
          maxlength="320"
          rows="7"
          class="text-gray-800 w-full p-6 rounded-xl"
        ></textarea>
      </div>
      <Button v-if="feedbackText.length > 0" @buttonClick="submitFeedback" text="Submit" classes="bg-green-500 mb-3" />
      <Button @buttonClick="goBack" text="Go back" />
    </section>
    <Footer />
  </div>
</template>

<script>
import Logo from "../components/Logo";
import Footer from "../components/Footer";
import Button from "../components/Button";
import router from "../router/index";

export default {
  name: "Feedback",
  components: { Logo, Footer, Button },
  data() {
    return {
      feedbackText: "",
    };
  },
  methods: {
    goBack() {
      if (this.feedbackText.length > 0) {
        this.$dialog
          .confirm({
            title: "Go back",
            text: "Are you sure you want to abandon your feedback?",
            okButtonText: "Yes",
            cancelButtonText: "No",
          })
          .then((result) => {
            if (result.isOk) router.push({ name: "Home" });
          });
      } else {
        router.push({ name: "Home" });
      }
    },
    submitFeedback() {
      if (this.feedbackText.length > 0) {
        this.$socket.emit("leaveGameFeedback", this.feedbackText);
      }
    },
  },
  sockets: {
    gameFeedbackSuccess() {
      this.$dialog.alert("You're awesome!", "Thank you for helping improve the game. :)");
      this.feedbackText = "";
    },
  },
};
</script>
