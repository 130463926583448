<template>
  <span
    class="
      bg-blue-400
      px-2
      py-0.5
      rounded-lg
      ml-2
      text-xs
      flex flex-col
      justify-center
    "
    >{{ text }}</span
  >
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>