<template>
  <section>
    <section v-if="!joined" class="flex flex-col">
      <label for="name" class="text-xl mb-3">Enter your name:</label>
      <input
        @keydown.enter="setName"
        id="name"
        ref="nameinput"
        type="text"
        v-model="name"
        placeholder="Doesn't have to be your real name"
        autocomplete="off"
        minlength="1"
        maxlength="20"
        class="w-full p-3 rounded-xl text-gray-800 mb-3"
      />
      <Button @buttonClick="setName" text="Set name" classes="bg-green-500" />
    </section>
    <section v-else class="flex flex-col">
      <div v-if="gameState.round === 0" class="mb-10 flex flex-col">
        <h1 class="text-3xl text-center mb-8 font-semibold">
          <span>Room code: </span>
          <span class="text-green-500">{{ roomCode }}</span>
        </h1>
        <label for="roomLink" class="mb-2 text-lg">Invite link:</label>
        <div class="flex justify-center items-center text-sm">
          <input id="roomLink" type="text" class="w-full text-gray-800 pl-3 py-3 rounded-l-xl" :value="inviteLink" />
          <button
            @click="inviteLinkToClipboard"
            class="py-3 pr-5 pl-4 bg-green-500 transition hover:opacity-80 active:opacity-60 rounded-r-xl font-bold"
          >
            Copy
          </button>
        </div>
        <p class="text-center mt-3">
          Playing remotely? Join a
          <a
            :href="'https://meet.jit.si/Quicksplain' + roomCode"
            target="_blank"
            rel="noopener noreferrer"
            class="text-green-500 font-semibold hover:underline"
          >
            video chat</a
          >!
        </p>
      </div>
      <div v-else>
        <h1 class="text-3xl text-center mb-4 font-semibold">Results after round {{ gameState.round }}</h1>
        <h2 class="text-xl text-center mb-8">
          <span>Room code: </span>
          <span class="text-green-500 font-semibold">{{ roomCode }}</span>
        </h2>
      </div>
      <h2 class="mb-2 text-lg">Players ({{ gameState.joinOrder.length }}/8):</h2>
      <div class="mb-4">
        <div
          v-for="(playerID, i) in gameState.results"
          :key="'player' + i"
          class="bg-blue-500 py-3 px-4 rounded-xl mb-3"
        >
          <div v-if="gameState.players[playerID].name" class="flex justify-between items-center">
            <span v-if="gameState.round !== 0" class="mr-2">{{ i + 1 }}.</span>
            <span class="font-bold" :class="gameState.round === 0 ? ' mr-auto' : ''">{{
              gameState.players[playerID].name
            }}</span>
            <span v-if="gameState.round !== 0" class="ml-2 mr-auto whitespace-nowrap"
              >({{ gameState.players[playerID].score }} points)</span
            >
            <NameTag v-if="playerID === $socket.id" text="YOU" />
          </div>
          <div v-else class="text-blue-300 text-center w-full">Thinking of a name...</div>
        </div>
      </div>

      <p v-if="gameState.joinOrder.length < 2" class="text-center mb-4">Need more players to start the game.</p>
      <p v-else-if="!Object.values(this.gameState.players).every((player) => player.joined)" class="text-center mb-4">
        Waiting for players to choose names.
      </p>
      <ContinueButton
        v-else
        @buttonClick="voteNewRound"
        :text="gameState.round === 0 ? 'Start game' : 'Continue'"
        :gameState="gameState"
      />
      <Button @buttonClick="$emit('leaveButtonClick')" text="Leave room" classes="bg-red-600" />
    </section>
  </section>
</template>

<script>
import Button from "./Button";
import NameTag from "./NameTag";
import ContinueButton from "./ContinueButton";

export default {
  name: "LobbyPhase",
  components: { Button, NameTag, ContinueButton },
  props: {
    gameState: Object,
    joined: Boolean,
    roomCode: String,
    host: Boolean,
    allowStart: Boolean,
  },
  data() {
    return {
      name: "",
      inviteLink: `${window.location.href}`,
    };
  },
  mounted() {
    // focus on name input when it appears
    const focusInterval = setInterval(() => {
      if (this.joined || this.$refs.nameinput) {
        clearInterval(focusInterval);
      }
      if (this.$refs.nameinput) {
        this.$refs.nameinput.focus();
        clearInterval(focusInterval);
      }
    }, 10);
  },
  methods: {
    setName() {
      this.$socket.emit("setName", this.name);
    },
    inviteLinkToClipboard() {
      this.$dialog.alert("Link copied to clipboard!", "Invite your friends!");
      navigator.clipboard.writeText(this.inviteLink);
    },
    voteNewRound() {
      this.$socket.emit("voteNewRound");
    },
  },
};
</script>