<template>
  <ToggleButton
    @buttonClick="$emit('buttonClick')"
    :text="text + ' (' + gameState.nextTurnVotes.length + '/' + gameState.joinOrder.length + ')'"
    :toggled="gameState.nextTurnVotes.includes($socket.id)"
    classes="bg-green-500 my-3"
  />
</template>

<script>
import ToggleButton from "./ToggleButton";

export default {
  name: "ContinueButton",
  components: { ToggleButton },
  props: {
    text: String,
    gameState: Object,
  },
};
</script>