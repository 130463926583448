<template>
  <section class="flex flex-col">
    <header class="bg-blue-600">
      <h1 v-if="!turnPlayer" class="text-3xl text-center font-semibold">
        <span class="text-green-500">{{ gameState.players[gameState.nextPlayers[0]].name }}</span>
        is choosing a topic.
      </h1>
      <div v-else>
        <h1 class="text-3xl text-center my-4 font-semibold">Choose a topic.</h1>
        <p class="text-center">About which topic you know the most?</p>
      </div>
      <div ref="timeRemaining" class="text-center text-2xl py-6">{{ secondsRemaining }} seconds remaining</div>
      <div v-if="fixedTimeRemaining" class="text-center text-2xl fixed w-full left-0 top-0 bg-blue-600 py-6 z-10">
        {{ secondsRemaining }} seconds remaining
      </div>
    </header>
    <div v-if="turnPlayer" class="flex flex-col">
      <button
        v-for="topic in turnTopics"
        @click="chooseTopic(topic.id)"
        :key="'topic' + topic.id"
        class="
          bg-blue-500
          transition
          hover:opacity-80
          px-4
          py-3
          rounded-xl
          mb-3
          flex flex-col
          justify-between
          text-left
        "
      >
        <span class="text-xs text-blue-300">{{ topic.category }}</span>
        <span>{{ topic.name }}</span>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "TopicSelectionPhase",
  props: {
    gameState: Object,
    turnPlayer: Boolean,
    secondsRemaining: Number,
  },
  data() {
    return {
      turnTopics: [],
      fixedTimeRemaining: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    chooseTopic(topicID) {
      this.$socket.emit("chooseTopic", topicID);
    },
    handleScroll() {
      this.fixedTimeRemaining = window.scrollY >= this.$refs.timeRemaining.offsetTop;
    },
  },
  sockets: {
    turnTopicChoices(topicsString) {
      this.turnTopics = JSON.parse(topicsString);
    },
  },
};
</script>