<template>
  <button
    @click="$emit('buttonClick')"
    class="w-full py-3 rounded-xl transition hover:opacity-80 active:opacity-60 font-bold"
    :class="classes ? ' ' + classes : ' bg-blue-500'"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String,
    classes: String,
  },
};
</script>