<template>
  <div>
    <header class="max-w-screen-sm text-gray-100 pb-6 pt-12 px-4 text-center flex flex-col justify-center items-center">
      <Logo />
      <div class="sm:h-auto h-16 flex flex-col justify-center">
        <h2 class="text-lg sm:text-xl my-2 sm:my-8">
          <span class="">Can you quickly explain what</span>
          <span
            class="font-bold mx-1 sm:mx-1.5 border-r-2"
            :class="showCursor ? ' border-white' : 'border-transparent'"
            >{{ topicText }}</span
          >
          <span>is?</span>
        </h2>
      </div>
      <div class="w-full flex justify-center sm:flex-row flex-col">
        <div class="sm:w-72 max-w-64 p-6 sm:m-3 m-0 mb-0 mt-3 bg-blue-500 rounded-2xl">
          <h2 class="text-center text-xl mb-6 font-medium">Create room</h2>
          <Button @buttonClick="createRoom" text="Create" classes="bg-green-500" />
        </div>
        <div class="sm:w-72 max-w-80 p-6 sm:m-3 m-0 mb-0 mt-3 bg-blue-500 rounded-2xl">
          <h2 class="text-center text-xl mb-6 font-medium">Join room</h2>
          <label for="roomCodeBox" class="sr-only">Room code:</label>
          <input
            @keyup.enter="joinRoom"
            type="text"
            v-model="roomCode"
            placeholder="4-letter room code"
            class="w-full p-3 rounded-xl text-gray-800 mb-3"
          />
          <Button @buttonClick="joinRoom" text="Join" classes="bg-green-500" />
        </div>
      </div>
    </header>
    <section
      class="
        bg-gray-100
        text-gray-800
        w-full
        max-w-screen-sm
        py-12
        px-6
        sm:px-12
        rounded-none
        sm:rounded-3xl
        flex flex-col
        justify-center
        items-center
        text-justify
      "
    >
      <h2 class="text-2xl text-center font-semibold mb-8 text-blue-600">What is Quicksplain?</h2>
      <p class="mb-4">
        Quicksplain is a free party trivia game for groups of 2 to 8 people that tests your knowledge on a variety of
        topics.
      </p>
      <p class="mb-4">
        When it's your turn, you are given a list of topics to choose from and you have 60 seconds to quickly explain
        the chosen topic to the other players. They then evaluate your quicksplanation based on keywords that you
        mention. The best quicksplainer wins!
      </p>
      <p class="mb-4">
        Every player needs to join a room on this site with an internet-connected device to play this game.
      </p>
      <p class="mb-4">I would really appreciate it if you could leave feedback about what you think of the game!</p>
      <div class="w-full sm:w-72">
        <router-link to="/feedback">
          <Button text="Leave feedback" classes="bg-green-500 text-gray-100" />
        </router-link>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import router from "../router/index";
import Button from "../components/Button";
import Logo from "../components/Logo";
import Footer from "../components/Footer";

export default {
  name: "Home",
  components: { Button, Logo, Footer },
  data() {
    return {
      roomCode: "",
      topicIndex: 0,
      topicChangeInterval: null,
      blinkInterval: null,
      showCursor: true,
      topicText: "",
      exampleTopics: [
        "Kanye West",
        "The Office",
        "Elon Musk",
        "Space Invaders",
        "GTA San Andreas",
        "Rick Astley",
        "The Beatles",
        "The Room",
        "South Park",
        "Avatar",
        "Quicksplain",
        "Game of Thrones",
        "Breaking Bad",
        "Neutral Milk Hotel",
        "Rick and Morty",
        "IT Crowd",
        "moral uncertainty",
        "consequentialism",
        "string theory",
        "Harry Potter",
        "Lord of the Rings",
        "Dungeons and Dragons",
        "The Little Prince",
        "Radiohead",
        "King of the Hill",
        "Joy Division",
        "Nirvana",
        "Star Wars",
        "Titanic",
        "Birdemic: Shock and Terror",
        "Memento",
        "Minecraft",
        "quantum physics",
        "Guardians of the Galaxy",
        "The Shawshank Redemption",
        "Spirited Away",
        "2001: A Space Odyssey",
        "Black Mirror",
        "Linux",
        "Disco Elysium",
        "the meaning of life",
        "Dwarf Fortress",
        "the game you just lost",
        "artificial general intelligence",
        "superintelligence",
        "AI alignment",
        "effective altruism",
        "updog",
      ],
    };
  },
  sockets: {
    roomCode(roomCode) {
      router.push({
        name: "Room",
        params: { roomCode: roomCode },
      });
    },
  },
  mounted() {
    // Shuffle example topics
    this.exampleTopics = this.exampleTopics
      .map((a) => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value);

    this.newTopicIndex();
  },
  methods: {
    createRoom() {
      this.$socket.emit("createRoom");
    },
    joinRoom() {
      if (this.roomCode) {
        router.push({
          name: "Room",
          params: { roomCode: this.roomCode.toUpperCase() },
        });
      }
    },
    newTopicIndex() {
      //this.topicIndex = (this.topicIndex + 1) % this.exampleTopics.length;
      this.topicIndex = (this.topicIndex + 1) % this.exampleTopics.length;
      this.topicChangeInterval = setInterval(this.addLetter, 100);
      clearInterval(this.blinkInterval);
      this.showCursor = true;
    },
    addLetter() {
      this.topicText += this.exampleTopics[this.topicIndex][this.topicText.length];

      if (this.topicText === this.exampleTopics[this.topicIndex]) {
        clearInterval(this.topicChangeInterval);
        this.blinkInterval = setInterval(this.blinkCursor, 400);
        setTimeout(() => {
          this.topicChangeInterval = setInterval(this.removeLetter, 50);
          clearInterval(this.blinkInterval);
          this.showCursor = true;
        }, 1337);
      }
    },
    removeLetter() {
      this.topicText = this.topicText.substring(0, this.topicText.length - 1);

      if (this.topicText.length === 0) {
        clearInterval(this.topicChangeInterval);
        this.blinkInterval = setInterval(this.blinkCursor, 400);
        setTimeout(this.newTopicIndex, 700);
      }
    },
    blinkCursor() {
      this.showCursor = !this.showCursor;
    },
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.topicChangeInterval);
    next();
  },
};
</script>

<style scoped></style>