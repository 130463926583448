<template>
  <Button
    @buttonClick="$emit('buttonClick')"
    :text="text"
    :classes="classes + ' ' + (toggled ? 'opacity-50 hover:opacity-30' : 'hover:opacity-70')"
  />
</template>

<script>
import Button from "./Button";

export default {
  name: "ToggleButton",
  components: { Button },
  props: {
    text: String,
    toggled: Boolean,
    classes: String,
  },
};
</script>