import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import { VuePlausible } from "vue-plausible";

import VueTailwind from "vue-tailwind";
import vueTailwindSettings from "../vue-tailwind.config.js";
import "./assets/tailwind.css";

if (process.env.VUE_APP_PLAUSIBLE_API_HOST && process.env.VUE_APP_PLAUSIBLE_DOMAIN) {
  Vue.use(VuePlausible, {
    apiHost: process.env.VUE_APP_PLAUSIBLE_API_HOST,
    domain: process.env.VUE_APP_PLAUSIBLE_DOMAIN,
    hashMode: true
  });
  Vue.$plausible.enableAutoPageviews();
}

Vue.use(VueTailwind, vueTailwindSettings);

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketIO(process.env.VUE_APP_SOCKETS_URL, {
      path: process.env.VUE_APP_SOCKETS_PATH
    })
  })
);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
