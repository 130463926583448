<template>
  <main id="app" class="font-inter w-full text-gray-700 flex justify-center">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </main>
</template>

<script>
export default {
  sockets: {
    message(content) {
      this.$dialog.alert("Error", content);
    },
  },
};
</script>

