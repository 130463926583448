<template>
  <section class="flex flex-col">
    <header>
      <h1 class="text-3xl text-center mb-4 font-semibold flex flex-col">
        <span v-if="!turnPlayer" class="text-green-500 mb-2">
          {{ gameState.players[gameState.nextPlayers[0]].name }}
        </span>
        <span v-if="!turnPlayer">is quicksplaining</span>
        <span v-if="turnPlayer">Quicksplain</span>
        <span class="text-green-500 mt-2">{{ gameState.turnTopic.name }}</span>
      </h1>
      <p v-if="!turnPlayer" class="mb-3 text-center">
        Select keywords that
        <span v-if="!turnPlayer" class="text-green-500 mb-2">
          {{ gameState.players[gameState.nextPlayers[0]].name }}
        </span>
        mentions. Feel free to ask questions or give hints if they're struggling.
      </p>
      <div class="text-center">
        Guessed keywords: {{ gameState.guessedKeywordCount }}/{{ gameState.turnKeywordCount }}
      </div>
      <div ref="timeRemaining" class="text-center text-2xl py-6">{{ secondsRemaining }} seconds remaining</div>
      <div v-if="fixedTimeRemaining" class="text-center text-2xl fixed w-full left-0 top-0 bg-blue-600 py-6 z-10">
        {{ secondsRemaining }} seconds remaining
      </div>
    </header>
    <div v-if="!turnPlayer" class="flex flex-col">
      <button
        v-for="keyword in turnKeywords"
        @click="selectKeyword(keyword.id)"
        :key="'keyword' + keyword.id"
        class="bg-blue-500 px-4 py-3 rounded-xl mb-3 transition flex flex-col justify-between text-left"
        :class="keyword.guessed ? ' opacity-60 hover:opacity-40' : ' hover:opacity-80'"
      >
        {{ keyword.name }}
      </button>
    </div>
    <Sector
      v-else
      :ratio="secondsRemaining / 60"
      bgColor="#2563EB"
      sectorColor="#F3F4F6"
      classes="w-40 h-40 sm:h-60 sm:w-60 md:w-80 md:h-80 border-4 mt-4 my-10 mx-auto"
    />
    <div v-if="turnPlayer" class="h-12">
      <Button
        v-if="secondsRemaining <= 57"
        @buttonClick="endGuessing"
        text="Done quicksplaining"
        classes="bg-red-600"
      />
    </div>
  </section>
</template>

<script>
import Button from "./Button";
import Sector from "./Sector";

export default {
  name: "GuessingPhase",
  components: { Button, Sector },
  props: {
    gameState: Object,
    turnPlayer: Boolean,
    secondsRemaining: Number,
  },
  data() {
    return {
      turnKeywords: [],
      fixedTimeRemaining: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    selectKeyword(keywordID) {
      this.$socket.emit("selectKeyword", keywordID);
      // toggle "guessed" attribute of keyword
      this.turnKeywords = this.turnKeywords.map((keyword) =>
        keyword.id === keywordID ? { ...keyword, guessed: !keyword.guessed } : keyword
      );
    },
    endGuessing() {
      this.$socket.emit("endGuessing");
    },
    handleScroll() {
      this.fixedTimeRemaining = window.scrollY >= this.$refs.timeRemaining.offsetTop;
    },
  },
  sockets: {
    turnKeywords(keywordsString) {
      this.turnKeywords = JSON.parse(keywordsString);
      this.turnKeywords.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>