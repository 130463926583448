<template>
  <div class="min-h-screen p-4 max-w-md w-full bg-blue-600 text-gray-100 flex flex-col justify-center">
    <LobbyPhase
      v-if="gameState.gamePhase === 0"
      @leaveButtonClick="leaveRoom"
      :gameState="gameState"
      :joined="joined"
      :roomCode="roomCode"
      :host="host"
      :allowStart="allowStart"
    />
    <TopicSelectionPhase
      v-else-if="gameState.gamePhase === 1"
      :gameState="gameState"
      :turnPlayer="turnPlayer"
      :secondsRemaining="secondsRemaining"
    />
    <GuessingPhase
      v-else-if="gameState.gamePhase === 2"
      :gameState="gameState"
      :turnPlayer="turnPlayer"
      :secondsRemaining="secondsRemaining"
    />
    <ResultsPhase v-else-if="gameState.gamePhase === 3" :gameState="gameState" :turnPlayer="turnPlayer" />
  </div>
</template>

<script>
import LobbyPhase from "../components/LobbyPhase";
import TopicSelectionPhase from "../components/TopicSelectionPhase";
import GuessingPhase from "../components/GuessingPhase";
import ResultsPhase from "../components/ResultsPhase";
import router from "../router/index";

export default {
  name: "Room",
  components: {
    LobbyPhase,
    TopicSelectionPhase,
    GuessingPhase,
    ResultsPhase,
  },
  data() {
    return {
      gameState: {},
      joined: false,
      host: false,
      allowStart: false,
      turnPlayer: false,
      inviteLink: `${window.location.href}`,
      secondsRemaining: 0,
    };
  },
  props: {
    roomCode: String,
  },
  beforeMount() {
    this.roomCode = this.roomCode.toUpperCase();
    this.$socket.emit("joinRoom", this.roomCode);
  },
  methods: {
    disconnect() {
      router.push({ name: "Home" }).catch(() => {});
    },
    leaveRoom() {
      router.push({ name: "Home" }).catch(() => {});
    },
  },
  sockets: {
    newGameState(gameStateString) {
      this.gameState = JSON.parse(gameStateString);
      // console.log(this.gameState);
      this.host = this.gameState.joinOrder[0] === this.$socket.id;
      this.joined = !!this.gameState.players[this.$socket.id].name; // Joined this room only if this client has a non-empty name in the gamestate
      this.turnPlayer = this.gameState.nextPlayers[0] === this.$socket.id;
      this.allowStart =
        Object.values(this.gameState.players).every((player) => player.joined) &&
        Object.values(this.gameState.players).length > 1;
      this.secondsRemaining = this.gameState.secondsRemaining;
    },
    secondsRemaining(seconds) {
      this.secondsRemaining = seconds;
    },
    kick(reason) {
      this.$dialog.alert("Left the room", reason);
      router.push({ name: "Home" });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.joined) {
      this.$dialog
        .confirm({
          title: "Leave room",
          text: "Are you sure you want to leave the room?",
          okButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.isOk) {
            this.$socket.emit("leaveRoom");
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
  },
};
</script>