<template>
  <section class="flex flex-col">
    <h1 class="text-3xl text-center mb-4 font-semibold">Results</h1>
    <p class="text-center mb-2">
      Guessed keywords: {{ gameState.guessedKeywordCount }}/{{ gameState.turnKeywordCount }}
    </p>
    <p class="text-center mb-6">
      <span v-if="!turnPlayer" class="text-green-500 font-semibold">
        {{ gameState.players[gameState.nextPlayers[0]].name }}
      </span>
      <span v-else>You</span>
      earned {{ gameState.guessedKeywordCount * 100 }} points for quicksplaining
      <span class="text-green-500 font-semibold">{{ gameState.turnTopic.name }}</span
      >!
    </p>
    <p class="text-center mb-6">Tap on a keyword to vote for a correction.</p>
    <div>
      <button
        v-for="keywordID in gameState.resultKeywordOrder"
        :key="'keyword' + keywordID"
        @click="voteKeywordCorrection(keywordID)"
        class="w-full px-4 py-3 rounded-xl mb-3 flex items-center justify-between text-left transition"
        :class="
          (gameState.resultKeywords[keywordID].votes.includes($socket.id)
            ? ' opacity-60 hover:opacity-40'
            : ' hover:opacity-80') + (gameState.resultKeywords[keywordID].guessed ? ' bg-green-500' : ' bg-blue-500')
        "
      >
        <span class="mr-auto">{{ gameState.resultKeywords[keywordID].name }}</span>
        <span
          v-if="gameState.resultKeywords[keywordID].votes.length > 0"
          class="ml-2 px-2 py-0.5 rounded-lg text-sm flex items-center"
          :class="gameState.resultKeywords[keywordID].guessed ? 'bg-blue-500' : 'bg-green-500'"
          >{{ gameState.resultKeywords[keywordID].votes.length }}/{{ gameState.joinOrder.length }}</span
        >
      </button>
    </div>
    <ContinueButton @buttonClick="voteNextTurn" text="Continue" :gameState="gameState" />
    <Button
      @buttonClick="toggleFeedback"
      :classes="givingFeedback ? '' : 'bg-green-500'"
      :text="givingFeedback ? 'Close feedback form' : 'Leave feedback about this topic'"
    />
    <div v-if="givingFeedback" class="flex flex-col mt-10">
      <h2 class="text-xl mb-3 text-center font-semibold">Topic feedback</h2>
      <ToggleButton
        @buttonClick="chooseFeedbackType(1)"
        text="Leave a comment"
        :classes="'mb-3 bg-blue-500'"
        :toggled="feedbackType != 0 && feedbackType != 1"
      />
      <ToggleButton
        @buttonClick="chooseFeedbackType(2)"
        text="Add a keyword"
        classes="mb-3 bg-blue-500"
        :toggled="feedbackType != 0 && feedbackType != 2"
      />
      <ToggleButton
        @buttonClick="chooseFeedbackType(3)"
        text="Remove a keyword"
        classes="mb-3 bg-blue-500"
        :toggled="feedbackType != 0 && feedbackType != 3"
      />
      <ToggleButton
        @buttonClick="chooseFeedbackType(4)"
        text="Modify a keyword"
        classes="mb-9 bg-blue-500"
        :toggled="feedbackType != 0 && feedbackType != 4"
      />

      <div v-if="feedbackType === 3 || feedbackType === 4" class="flex flex-col">
        <label for="keywordSelection" class="mb-1">Select keyword</label>
        <select
          @change="updateModification"
          v-model="selectedKeyword"
          id="keywordSelection"
          placeholder="Select keyword"
          class="w-full p-3 rounded-xl text-gray-800 mb-3 cursor-pointer transition hover:bg-gray-200"
        >
          <option value="null" disabled selected>Select a keyword</option>
          <option
            v-for="keywordOptionID in gameState.resultKeywordOrder"
            :key="'keywordOption' + keywordOptionID"
            :value="keywordOptionID"
          >
            {{ gameState.resultKeywords[keywordOptionID].name }}
          </option>
        </select>
      </div>
      <div v-if="feedbackType === 2 || feedbackType === 4" class="flex flex-col">
        <label for="newKeyword" class="mb-1">New keyword</label>
        <input
          v-model="newKeyword"
          id="newKeyword"
          type="text"
          placeholder="New keyword"
          minlength="1"
          maxlength="128"
          class="w-full p-3 rounded-xl text-gray-800 mb-3"
        />
      </div>
      <div v-if="feedbackType !== 0" class="flex flex-col">
        <label for="comment" class="mb-1">Comment<span v-if="feedbackType !== 1"> (optional)</span></label>
        <input
          v-model="comment"
          id="comment"
          type="text"
          placeholder="Comment"
          minlength="1"
          maxlength="128"
          class="w-full p-3 rounded-xl text-gray-800 mb-3"
        />
      </div>
      <Button v-if="feedbackType !== 0" @buttonClick="submitFeedback" text="Submit" classes="bg-green-500 mt-3" />
    </div>
    <div ref="feedbackBottom"></div>
  </section>
</template>

<script>
import Button from "./Button";
import ContinueButton from "./ContinueButton";
import ToggleButton from "./ToggleButton";

export default {
  name: "ResultsPhase",
  components: { Button, ContinueButton, ToggleButton },
  props: {
    gameState: Object,
    turnPlayer: Boolean,
  },
  data() {
    return {
      givingFeedback: false,
      feedbackType: 0,
      comment: "",
      newKeyword: "",
      selectedKeyword: null,
    };
  },
  methods: {
    voteKeywordCorrection(keywordID) {
      this.$socket.emit("voteCorrection", keywordID);
    },
    voteNextTurn() {
      this.$socket.emit("voteNextTurn");
    },
    toggleFeedback() {
      // Remove next turn vote, so it doesn't interrupt feedback
      if (this.givingFeedback) {
        this.givingFeedback = false;
      } else {
        if (this.gameState.nextTurnVotes.includes(this.$socket.id)) {
          this.voteNextTurn();
        }
        this.givingFeedback = true;
        this.feedbackType = 0;
        this.$nextTick(function () {
          this.$refs.feedbackBottom.scrollIntoView({
            behavior: "smooth",
          });
        });
      }
    },
    chooseFeedbackType(feedbackTypeID) {
      if (feedbackTypeID === this.feedbackType) {
        this.feedbackType = 0;
      } else {
        this.feedbackType = feedbackTypeID;
      }

      this.newKeyword = "";
      this.comment = "";
      this.selectedKeyword = null;

      this.$nextTick(function () {
        this.$refs.feedbackBottom.scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    updateModification() {
      if (this.feedbackType === 4) {
        this.newKeyword = this.gameState.resultKeywords[this.selectedKeyword].name;
      }
    },
    submitFeedback() {
      if (
        this.gameState.resultKeywordOrder.some(
          (keywordID) => this.gameState.resultKeywords[keywordID].name === this.newKeyword
        )
      ) {
        this.$dialog.alert("Error", "Keyword already exists");
      } else if (this.selectedKeyword || this.comment || this.newKeyword) {
        this.$socket.emit("leaveFeedback", this.feedbackType, this.selectedKeyword, this.comment, this.newKeyword);
      }
    },
  },
  sockets: {
    feedbackSuccess() {
      this.$dialog.alert("You're awesome!", "Thank you for helping improve the game. :)");
      this.toggleFeedback();
    },
  },
};
</script>